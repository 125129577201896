/** Road **/

import React from "react";
import { gsap } from "gsap"
import { frameAnimation, preloadImages } from "../../helpers"
import PrevNext from "../../components/navigation/prevnext"
import SEO from "../../components/seo"
import Preloader from "../../components/preloader/preloader"
import Loader from "../../components/preloader/loader"


let globalState, data, preloadData, preloadCounter = 0;


class HereToWorkTitlePage extends React.Component {

  constructor(props) {
    super(props);

    //update global state and assets data
    globalState = this.props.location.state;
    data = globalState.assets[0];
    preloadData = globalState.assets[1];

    this.timeout1 = null;

    //refs
    this.titleRef = null;
  }

  state = {
    isActivated: false,
    loadNum: 0,
    totalLoadNum: 0
  }

  componentDidMount = () => {

    //check if is active
    this.setState({ isActivated: globalState.isActivated });
    //If yes animate in title
    if(globalState.isActivated){
      this.initTitle();
    }
  }

  initTitle = () => {

    //1. Set isActivated locally and globally
    this.setState({ isActivated: true });
    globalState.updateIsActivated(true);

    //2. add digging base track
    globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + data.soundfile1);
    globalState.baseAudioRef.updateTrack(2, false);
    

    //3. Animate in Title
    this.timeout1 = setTimeout(function () {
      gsap.to(this.titleRef, 0.1, { autoAlpha: 1.0 });
      frameAnimation(this.titleRef, 543, 132, 4, 9, 35, 0.2, 0);
    }.bind(this), 100);

    //4. Preload assets from next page
    preloadImages([
      globalState.cdnUrl + preloadData.text1,
      globalState.cdnUrl + preloadData.slide1,
      globalState.cdnUrl + preloadData.slide2,
      globalState.cdnUrl + preloadData.slide3,
      globalState.cdnUrl + preloadData.slide4,
      globalState.cdnUrl + preloadData.slide5,
      globalState.cdnUrl + preloadData.slide6,
      globalState.cdnUrl + preloadData.slide7,
      globalState.cdnUrl + preloadData.slide8,
      globalState.cdnUrl + preloadData.slide9,
      globalState.cdnUrl + preloadData.slide10,
    ], this.preloadCallback, true);
  }



  preloadCallback = (loadedImgNum, totalImgNum) => {
    preloadCounter++;

    //update loader properties in state
    this.setState({ loadNum: preloadCounter });
    this.setState({ totalLoadNum: totalImgNum });
  }

  //Navigate to the next page after preloading is complete
  goNext = () => {
    setTimeout(() => {
      if(globalState.transitionDirection === "prev") {
        this.refs.prevNextRef.showPrevNextBtns();
      } else {
        this.refs.prevNextRef.goNext("/here-to-work/1", "slide");
      }
    }, 500);
  }

  componentWillUnmount() {

    //Add base track
    //globalState.baseAudioRef.updateTrack(2, false);

  }






  render() {

    return (

      <>

        <link rel="prefetch" href="/here-to-work/1" />

        <PrevNext globalState={globalState} ref="prevNextRef"
          nextLocation="/here-to-work/1" prevLocation="/cosmos-954/5"
        />

        {this.state.isActivated ?
          <div className="fullpage-wrapper bg--white flex center">
            <SEO title="Pine Point - Here to Work" />

            <div className="chapterTitle" ref={div => this.titleRef = div} alt="Here to Work" style={{ width: "543px", height: "132px", backgroundImage: `url(${globalState.cdnUrl + data.title})` }} />

            <Loader state={this.state} callback={this.goNext} />

          </div> :
          <Preloader ref="preloaderRef" globalState={globalState} data={data} callback={this.initTitle} />
        }
        

      </>
    );
  }


};

export default HereToWorkTitlePage
