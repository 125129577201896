import React from "react";
import { frameAnimation, preloadImages } from "../../helpers"
//import assetsData from "../../data/assets"

import gsap from "gsap";


let globalState;

class Preloader extends React.Component {

    constructor(props) {
        super(props);
        this.preloaderRef = this.preloaderBgRef = this.activateRef = null;
        this.reqAnimFrame = null;
        //update global state
        globalState = props.globalState;
    }
    
    state = {
        styles: {
            top: 0,
            left: 0
        }
    }
    

    componentDidMount() {
        
        let newTop = window.innerHeight / 2;
        let newLeft = window.innerWidth / 2;
        this.setState({
            styles: {
                top: newTop,
                left: newLeft
            }
        });

        frameAnimation(this.preloaderRef, 226, 150, 2, 3, 6, 0.2, -1);
        this.reqAnimFrame = window.requestAnimationFrame(this.update);

        console.log("preload: ");
        console.log(globalState.assets[0].title);
        let title = globalState.assets[0].title;

        if(title === undefined){
            //no title, just set to image path that exists
            title = "/images/global/play-arrow.png";
        }

        preloadImages([
            globalState.cdnUrl + "/images/global/play-arrow-over.png",
            globalState.cdnUrl + "/images/global/prev.png",
            globalState.cdnUrl + "/images/global/next.png",
            globalState.cdnUrl + "/images/global/chapter-loader-base.gif",
            globalState.cdnUrl + "/images/global/chapter-loader-top.gif",
            globalState.cdnUrl + "/images/global/slideshowControl/nextBlank.png",
            globalState.cdnUrl + "/images/global/slideshowControl/backBlank.png",
            globalState.cdnUrl + title,
        ], this.preloadCallback);

        if(this.props.preload === true){
            this.activateRef.style.display = "none";
        }
    }

    preloadCallback = () => {
        console.log("preloader images loaded");
    }

    activate = (e) => {

        e.preventDefault();
        //this.setState({isActivated: true});
        //globalState.updateIsActivated(true);
        //console.log(globalState);
        //if (this.props.withSound) {
            //Add base track
        //  globalState.baseAudioRef.updateTrack(1, globalState.cdnUrl + assetsData("intro")[0].soundfile1);
        //}
        
        try{
            let s = new Audio(globalState.cdnUrl + globalState.silence);
            s.play();
        } catch (e) {
            console.log(e);
        }
        //sound.pause();
        //sound.currentTime = 0;

        
        //globalState.baseAudioRef.updateTrack(1, globalState.silence); 
        //globalState.baseAudioRef.updateTrack(2, globalState.silence); 
        //globalState.baseAudioRef.updateTrack(3, globalState.silence); 
        //globalState.baseAudioRef.updateTrack(4, globalState.silence); 

        gsap.to(this.activateRef, 0.2, { autoAlpha: 0 });
        gsap.to(this.preloaderRef, 0.2, { autoAlpha: 0 });
        gsap.to(this.preloaderBgRef, 0.2, { autoAlpha: 0, delay: 0.2, /*onComplete: this.props.callback*/ });
        this.props.callback();
    }

    update = (e) => {

        //let prevTop = this.state.styles.top;
        //let prevLeft = this.state.styles.left;
        let strength = 25;
        let t = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
        }
        t.x += Math.random() * strength - strength / 2;
        t.y += Math.random() * strength - strength / 2;

        let newTop = window.innerHeight / 2;
        let newLeft = window.innerWidth / 2;
        newTop -= (newTop - t.y) * 0.1;
        newLeft -= (newLeft - t.x) * 0.1;


        this.setState({
            styles: {
                top: newTop,
                left: newLeft
            }
        });


        this.reqAnimFrame = window.requestAnimationFrame(this.update);
    }

    showPlayBtn = () => {
        this.activateRef.style.display = "block";
    }

    componentWillUnmount() {
        console.log("cancelAnimationFrame");
        window.cancelAnimationFrame(this.reqAnimFrame);
    }

    render() {

        return (
            <>
                <div className="fullpage-wrapper bg--black" ref={div => this.preloaderBgRef = div}>
                    <div className="preloader" ref={div => this.preloaderRef = div} style={this.state.styles} />

                    <a href="true" ref={div => this.activateRef = div} className="activate-btn" onClick={this.activate}>
                        <div className="play-tape" />
                        <button className="play-arrow" />
                    </a>
                </div>
            </>
        );
    }

}

export default Preloader;