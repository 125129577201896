import React from "react";
import gsap from "gsap";


let _startTime,
    _percentLoadedShown,
    _progressWidth,
    _realPercentLoaded;

const _maxWidth = 500;
const minAniTime = 2500;
let loadNum, totalLoadNum;

class Loader extends React.Component {

    
    update = () => {
        
        loadNum = this.props.state.loadNum;
        totalLoadNum = this.props.state.totalLoadNum;
        //console.log("update loader: " + loadNum + ", " + totalLoadNum);
        
        _percentLoadedShown = loadNum / totalLoadNum;
        _progressWidth = _maxWidth * (_percentLoadedShown * 0.01);

        let time = new Date();
        let diff = time - _startTime;

        _realPercentLoaded = loadNum/totalLoadNum * 100;

        let maxValueAllowed = minAniTime > diff ? diff / minAniTime * 100 : 100;

        if (_realPercentLoaded > maxValueAllowed) {
            _percentLoadedShown = maxValueAllowed;
        } else {
            _percentLoadedShown = _realPercentLoaded;
        }

        _progressWidth = (_maxWidth * _percentLoadedShown * 0.01);
        
        this.refs.progressRef.style.width = _progressWidth+"px";

        this.reqAnimFrame = window.requestAnimationFrame(this.update);
         
        if (_progressWidth >= 500) {
            window.cancelAnimationFrame(this.reqAnimFrame);
            this.refs.loaderRef.style.visibility = "hidden";
            this.props.callback();
        }
        
    }

    componentDidMount() {
        _startTime = new Date();
        _percentLoadedShown = 0;
        _progressWidth = 0;
        _realPercentLoaded = 0;
        this.reqAnimFrame = window.requestAnimationFrame(this.update);

        gsap.to(this.refs.loaderRef, 0.3, {autoAlpha: 1, delay: 0.2 });
    }

    componentWillUnmount() {
        window.cancelAnimationFrame(this.reqAnimFrame);
    }


    render() {

        return (

            <div className="loader" ref="loaderRef">
                <div className="progress" ref="progressRef"></div>
            </div>
        )
    }


};

export default Loader;